import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { extend, QueryContext } from '@thd-nucleus/data-sources';
import { useStore, ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { CustomerContext } from '@thd-olt-functional/customer-information';
import { LayoutRenderer } from '@thd-olt-component-react/layout-renderer';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { useLocation } from '@thd-olt-component-react/router';
import { useUrlTmxId } from '../../hooks/useUrlTmxId';
import { componentOptions } from './component-options';
import {
  CUSTOMER_TYPES,
  userAuthorizationStatus,
  getMarketingCloudVisitorID,
  getGuestUserId,
  getHydrationOptions,
  getProCustomerSegment,
} from '../../utils';
import {
  proDataModelServerSide,
  proDataModelClientSide,
  homepagePreviewByIDDataModelServerSide,
  homepagePreviewByIDDataModelClientSide
} from '../../datamodels/home';
import { components } from '../../components/components';
import { ProHomePageLoadingLayout } from './ProHomePageLoadingLayout';
import { usePreviewByID } from '../../hooks';
import { HomepageProvider, HomepageContext } from '../../components/context';
import { HomepageMetadataContainer } from '../../components/metadata/HomepageMetadataContainer';

let layoutDataModel = {
  server: extend({}, proDataModelServerSide),
  client: proDataModelClientSide
};

export const ProHomepage = ({ slug, thdSEOBotDetection }) => {
  declareContexts([QueryContext, ExperienceContext, HomepageContext, CustomerContext], { freeze: true });
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const store = useStore({ varnish: false });
  const userState = {
    mcvisID: getMarketingCloudVisitorID(),
    userAuthStatus: userAuthorizationStatus(),
    guestUserId: getGuestUserId(),
    proCustomerSegment: getProCustomerSegment(),
  };

  const contentfulPreviewId = usePreviewByID();
  const proHPBackup = useConfigService('proHPBackup');
  const { search: querystring, pathname } = useLocation();

  const pathWithQueryParams = `${pathname}${querystring}`;
  const isPreviewPage = pathWithQueryParams.indexOf('render*') > -1
    || pathWithQueryParams.indexOf('contentPV') > -1
    || pathWithQueryParams.indexOf('bassPV') > -1;

  /**
   * This is not a class in the CSS sense. It's referring
   * to the type of layout we have in Contentful, which
   * at the time of writing seems to be a choice between
   * PersonalizedHomepage and EducationalLayout.
   */
  let layoutClass;

  if (contentfulPreviewId) {
    layoutDataModel = {
      server: homepagePreviewByIDDataModelServerSide,
      client: homepagePreviewByIDDataModelClientSide
    };
    layoutClass = 'PersonalizedHomePage';
  }

  useEffect(function triggerHomepageLoadEvent() {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'pro-homepage.page-load',
      'homepage',
    );
  }, []);

  useEffect(function setThdOnlineChannelCookie() {
    if (channel === 'mobile' && !isConsumerApp) {
      const encodedValue = encodeURIComponent('E1=S2');
      window.cookieUtils.fed_CreateCookie(
        'THD_ONLINE_CHANNEL',
        encodedValue,
        1,
      );
    }
  }, []);

  const { guestUserId, mcvisID, userAuthStatus, proCustomerSegment } = userState;

  return (
    <HomepageProvider
      value={{
        thdSEOBotDetection,
        hydrationOptions: getHydrationOptions(channel, isConsumerApp),
        customerType: CUSTOMER_TYPES.B2B,
        urlTmxId: useUrlTmxId(),
        guestUserId,
        mcvisID,
        store,
        userAuthStatus,
        proCustomerSegment,
      }}
    >
      <LayoutRenderer
        components={components}
        componentOptions={componentOptions}
        slug={slug}
        contentfulPreviewId={contentfulPreviewId}
        layoutClass={layoutClass}
        layoutDataModel={layoutDataModel}
        loadingLayout={<ProHomePageLoadingLayout />}
        onContentInformationApiError={() => {
          if (typeof window !== 'undefined') {
            window.location.replace(proHPBackup);
          }
        }}
        ssr
        renderMetadata={({ preloadedImages, metadata }) => {
          return (
            <HomepageMetadataContainer
              isPreviewPage={isPreviewPage}
              preloadedImages={preloadedImages}
              metadata={metadata}
            />
          );
        }}
        customerType={CUSTOMER_TYPES.B2B}
      />
    </HomepageProvider>
  );
};

ProHomepage.dataModel = layoutDataModel;

ProHomepage.propTypes = {
  slug: PropTypes.string,
  thdSEOBotDetection: PropTypes.bool
};

ProHomepage.defaultProps = {
  slug: undefined,
  thdSEOBotDetection: false
};

ProHomepage.displayName = 'ProHomepage';
